import * as React from "react"
import Menu from "../components/menu"
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import { subtitle, title, textContainer } from "../styles"
import clsx from "clsx"
import Seo from "../components/seo"

// markup
const DetailBmPage = (props) => {
  return (
    <div> 
      <Seo />
      <Menu/>
      <div className="max-w-screen-xl mx-5 xl:mx-auto md:grid md:grid-cols-3 md:gap-8">

        <div className="max-w-screen-lg mt-12 text-center col-span-2">
          <StaticImage className="w-4/6 md:w-full"
            src="../images/bm-mockup.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-sm mt-12">
          <h1 className={ title }>
            Product Design - BrioMobile
          </h1>
          <div className={ clsx(textContainer, "text-justify") }>
            <p className="mb-4">Portima is a leading software provider for broker and insurance companies in Belgium. Portima’s mission is to help the brokers become a person of trust for their clients in a digital era.</p>
            <p className="mb-4">Brio is a Broker space and more specifically a client management software suiting all of the broker in insurance allowing them to work more efficiently and free some time for what really counts, their clients. The interface is built to allow brokers to manage their office easily and proactively, to communicate fast and directly with their clients. There is also a support and assistance team foreseen if in need of advice.</p>
            <p>The goal of this project was to rethink the mobile and lighter version of Brio, namely BrioMobile. The idea was to enable brokers to work efficiently even when on the road for clients meetings, and provide them with relevant information regarding their clients but also allowing them to perform any changes in their clients insurance files.</p>
          </div>

          <div className={ textContainer }>
            <h2 className = { subtitle }> Client </h2>
            <p className="mb-4">Portima</p>
            <h2 className = { subtitle }> Type </h2>
            <p className="mb-4">Product Design</p>
            <h2 className = { subtitle }> Year </h2>
            <p className="mb-4">2020</p>
          </div>
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/brio-01.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3 ">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/brio-02.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

      </div>
      <Footer/>
    </div>
     
  
  )
}


export default DetailBmPage